import { Row, Col } from "antd"
import { MyContext } from '../../App'
import { useState, useContext } from "react"

export function Change() {

      const { AppData } = useContext(MyContext)

      const support_url = AppData.brand === "fastquote"
            ? `https://www.fastquote.co.uk/support/`
            : `https://boxguard.uk/support/important-documents/`

      return (

            <>
                  <p>Sorry. This feature is not yet available online.</p>
                  <p>Please raise a <a href={support_url} target="_blank">customer support ticket</a> over on our main website. Our team are on hand to help you.</p>
            </>
      )

}