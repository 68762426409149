import { MyContext } from '../../App'
import { useContext } from "react"
import { PiFilePdf as PdfIcon, PiDownloadSimple as DownloadIcon } from "react-icons/pi"
import { viewPdf } from "../../_utils/_pdf/helper"
import { message } from "antd"

export function Documents() {

      const { AppData, UserData } = useContext(MyContext)

      const domain = AppData.brand === "fastquote"
            ? "fastquote.co.uk"
            : "boxguard.uk"

      const driver = UserData.policy.driver_type.toLowerCase() === "c" ? "c" : "r"

      const viewStatic = (type) => {

            let url
            switch (type) {
                  case 'summary': url = `assets.${domain}/docs/policy/${driver}/summary.pdf`; break
                  case 'wording': url = `assets.${domain}/docs/policy/${driver}/wording.pdf`; break
                  case 'inventory': url = `assets.${domain}/docs/policy/${driver}/inventory.pdf`; break
                  case 'terms': url = `assets.${domain}/docs/general/toba.pdf`; break
                  case 'complaint': url = `assets.${domain}/docs/general/complaints.pdf`; break
                  default: url = false
            }

            return url
                  ? window.open(`https://${url}`, '_blank', 'toolbar=0,location=0,menubar=0')
                  : message.error("Something went wrong. Please try again!")

      }

      return (

            <>

                  <h2>Confirmation of Insurance Cover</h2>

                  <div className="pdf-link clickable" onClick={() => viewPdf("coc", UserData, AppData)}>
                        <DownloadIcon className="pdf-link-icon" /> DOWNLOAD POLICY DOCUMENT
                  </div>

                  <h2 style={{ marginTop: "75px" }}>{UserData.policy.driver_type.toLowerCase() === "c" ? "Courier" : "Remover"} policy documents</h2>

                  <div className="pdf-link clickable" onClick={() => viewStatic('summary')}>
                        <PdfIcon className="pdf-link-icon" /> POLICY SUMMARY
                  </div>

                  <div className="pdf-link clickable" onClick={() => viewStatic('wording')}>
                        <PdfIcon className="pdf-link-icon" /> POLICY WORDING (FULL)
                  </div>

                  {UserData.policy.driver_type.toLowerCase() === "c"
                        ? null
                        :
                        <div className="pdf-link clickable" onClick={() => viewStatic('inventory')}>
                              <PdfIcon className="pdf-link-icon" /> VALUED INVENTORY
                        </div>
                  }

                  <h2 style={{ marginTop: "75px" }}>General business documents</h2>

                  <div className="pdf-link clickable" onClick={() => viewStatic('terms')}>
                        <PdfIcon className="pdf-link-icon" /> TERMS OF BUSINESS
                  </div>

                  <div className="pdf-link clickable" onClick={() => viewStatic('complaint')}>
                        <PdfIcon className="pdf-link-icon" /> MAKING A COMPLAINT
                  </div>

            </>
      )

}