import { isMobile } from "../helper"
import { coc } from "../_pdf/templates/coc/template"
var pdfMake = require('pdfmake/build/pdfmake.js')
var pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

export async function viewPdf(type, UserData, AppData) {

      let data

      // Apply appropriate template
      switch (type) {
            case 'coc': data = await coc(UserData, AppData); break
            default: break
      }

      // Build PDF (mobile should download, desktop opens in new tab)
      pdfMake.createPdf(data).download()

      return

}