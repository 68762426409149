import { ucFirst, get, deepMerge } from "../../../helper"
import { helpers as hf } from "../coc/helper-functions"
import { defaultPageConfig, defaultStyleConfig } from "../../config"

export async function coc(UserData, AppData) {

      // Assign data to p for later simplicity
      let p = UserData.policy

      // Grab some params from db
      let params = {
            brand: AppData.brand === "fastquote" ? "fastquote" : "boxguard",
            table: 'config',
            query: {
                  select: `excess_${hf.getDriverType(p) === "courier" ? "c" : "r"}_git as xsGit, excess_${hf.getDriverType(p) === "courier" ? "c" : "r"}_pl as xsPl`,
                  where: { id: 1 }
            }
      }

      let { result: cfg } = await get(params, AppData) // assign to cfg
      p = { ...p, ...cfg[ 0 ] } // spread into p for easier later access


      // Define custom page configuration for this template
      const customPageConfig = {
            info: {
                  title: 'Confirmation of Cover',
                  author: `${AppData.brand === "fastquote" ? "fastquote.co.uk" : "boxguard.uk"}`
            },
            footer: {
                  columns: [
                        { width: 70, fit: 50, qr: `https://${AppData.brand === "fastquote" ? "fastquote.co.uk" : "boxguard.uk"}` },
                        [
                              {
                                    text: `${AppData.brand === "fastquote" ? "fastquote.co.uk" : "boxguard.uk"}`,
                                    margin: [ 0, 5, 0, 0 ]
                              },
                              {
                                    width: '*', text: `Coversure Midlands Ltd t/a ${ucFirst(AppData.brand)} is authorised and regulated by the Financial Conduct Authority (FCA). Registered Office: Coversure House, 72 Sherwood Road, Bromsgrove, B60 3DR. Registered in England No. 4981212`, style: 'small'
                              }
                        ]
                  ],
                  margin: [ 40, 20, 40, 40 ]
            },
      }

      // Define images for this template
      const images = {
            logo: AppData.style.logo
      }

      // Define style configuration for this template
      const customStyleConfig = {
            styles: {
                  h3: {
                        color: AppData.style.color
                  }
            }
      }

      const style = deepMerge({}, defaultStyleConfig, customStyleConfig)

      console.log(style)

      // Then we just build the final data object for pdfmake
      const data = {
            ...{ ...defaultPageConfig, ...customPageConfig },
            content: [
                  { image: 'logo', width: 100 },
                  { text: 'CONFIRMATION OF COVER', style: 'h1' },
                  { text: 'Combined Goods in Transit & Liability Insurance', style: 'h2' },
                  { text: p.policy_number, style: 'h3' },
                  {
                        style: 'table',
                        table: {
                              widths: [ 205, '*' ],
                              body: hf.getSummary(p)
                        },
                        layout: style.styles.table
                  },
                  {
                        text: `Your policy provides insurance during the period of insurance stated above only in respect of those sections where the word ‘Insured’ is shown. Where there is no insurance the words ‘Not insured’ are shown.`, style: 'p'
                  },
                  {
                        style: 'table',
                        table: {
                              headerRows: 1,
                              widths: [ 205, '*' ],
                              body: hf.getGitBody(p)
                        },
                        layout: style.styles.table
                  },
                  {
                        style: 'table',
                        table: {
                              headerRows: 1,
                              widths: [ 205, '*' ],
                              body: hf.getPlBody(p)
                        },
                        layout: style.styles.table,

                  },
                  {
                        style: 'table',
                        table: {
                              headerRows: 1,
                              widths: [ 205, '*' ],
                              body: hf.getElBody(p)
                        },
                        layout: style.styles.table,
                        pageBreak: 'after'
                  },
                  { text: 'Statement of Facts', style: 'h3' },
                  { text: `We have quoted based on the below information. If any of the information is incorrect, you must notify us. Failure to do so may invalidate the insurance provided.`, style: 'p' },
                  { text: `Have you or any of your directors/partners ever been:`, style: 'p' },
                  {
                        style: 'table',
                        table: {
                              widths: [ '95%', '*' ],
                              body: hf.getSof1(p),
                        },
                        layout: style.styles.table,
                  },
                  { text: `Claims and other material information:`, style: 'p' },
                  {
                        style: 'table',
                        table: {
                              widths: [ '95%', '*' ],
                              body: hf.getSof2(p),
                        },
                        layout: style.styles.table,
                  },
                  hf.getRiskHandling(p, style),
                  hf.getEndorsements(p)
            ],
            images,
            ...style

      }

      // And return it for processing
      return data

}