export const defaultPageConfig = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [ 40, 40, 40, 100 ],
      info: {
            title: 'Document Title',
            author: `Dasher Group Limited`
      },
      footer: {
            columns: [
                  { width: 70, fit: 50, qr: `QR Code Available` },
                  [
                        {
                              text: `example.com`,
                              margin: [ 0, 5, 0, 0 ]
                        },
                        {
                              width: '*', text: `Maybe some footer text. Registered in England No. 654321`, style: 'small'
                        }
                  ]
            ],
            margin: [ 40, 20, 40, 40 ]
      },
}

export const defaultStyleConfig = {
      defaultStyle: {
            fontSize: 10,
            margin: [ 0, 10, 0, 0 ]
      },
      styles: {
            h1: {
                  fontSize: 24,
                  bold: true,
                  margin: [ 0, 20, 0, 0 ]
            },
            h2: {
                  fontSize: 18,
                  margin: [ 0, 1, 0, 0 ],
                  color: 'gray'
            },
            h3: {
                  fontSize: 16,
                  bold: true,
                  margin: [ 0, 4, 0, 0 ]
            },
            p: {
                  fontSize: 9,
                  margin: [ 0, 10, 0, 0 ]
            },
            small: {
                  fontSize: 7,
                  margin: [ 0, 5, 0, 0 ]
            },
            table: {
                  fontSize: 8,
                  margin: [ 0, 15, 0, 0 ],
                  paddingLeft: function (i, node) { return 5 },
                  paddingRight: function (i, node) { return 5 },
                  paddingTop: function (i, node) { return 3 },
                  paddingBottom: function (i, node) { return 3 },
                  hLineWidth: function (i, node) { return 1 },
                  vLineWidth: function (i, node) { return 1 },
                  hLineColor: function (i, node) { return '#899499' },
                  vLineColor: function (i, node) { return '#899499' }
            },
            green: {
                  color: "#1FA060"
            },
            red: {
                  color: "#D75347"
            },
      }
}