import { Row, Col, Tabs } from "antd"
import { MyContext } from '../App'
import { useState, useContext } from "react"
import { Header } from "../components/Header"
import { Documents } from "../components/sections/Documents"
import { Change } from "../components/sections/Change"
import { Claim } from "../components/sections/Claim"
import { Cancel } from "../components/sections/Cancel"

export function Home() {

      const { UserData } = useContext(MyContext)

      let [ activeTab, setActiveTab ] = useState("1")

      let items = [
            {
                  label: `Documents`,
                  key: '1',
                  children: <Documents />,
            },
            {
                  label: `Change`,
                  key: '3',
                  children: <Change />,
            },
            {
                  label: `Claim`,
                  key: '4',
                  children: <Claim />,
            },
            {
                  label: `Cancel`,
                  key: '5',
                  children: <Cancel />
            },
      ]

      return (

            <>

                  <Header />

                  <Row id="Header" justify="center" align="middle">

                        <Col xs={{ span: 22 }}>

                              <p style={{ fontWeight: 300, opacity: 0.5, marginBottom: "5px" }}>{UserData.policy.legal_entity_name}</p>
                              <h1>{UserData.policy.policy_number}</h1>

                              <Tabs style={{ marginTop: '50px' }} activeKey={activeTab} onChange={setActiveTab} items={items} />

                        </Col>

                  </Row>

            </>
      )

}