import LazyLoad from "react-lazyload"
import { useNavigate } from 'react-router-dom'

export function Image({ src, width, alt = '', clickable = false, navigate, style }) {

      const link = useNavigate()

      return (

            <LazyLoad width={width || 200} offset={100}>

                  <img
                        style={{ ...{ maxWidth: '100%' }, ...{ style } }}
                        src={src}
                        width={width || 'auto'}
                        alt={alt}
                        className={clickable ? "clickable" : undefined}
                        onClick={navigate ? () => link(navigate) : null}
                  />

            </LazyLoad>

      )
}
