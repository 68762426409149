import { Row, Col } from "antd"
import { useContext } from "react"
import { Image } from "../components/Image"
import { MyContext } from '../App'
import { ucFirst } from "../_utils/helper"

export function Header() {

      const { AppData } = useContext(MyContext)

      return (

            <Row id="Header" justify="center" align="middle">

                  <Col xs={{ span: 22 }}>

                        <Row>

                              <Col flex="auto">

                                    <Image
                                          src={AppData.style.logo}
                                          width={175}
                                          alt={`${ucFirst(AppData.brand)} Logo`}
                                          clickable={false}
                                          navigate={{}}
                                    />
                              </Col>

                        </Row>

                  </Col>

            </Row>

      )
}
