import { useNavigate, useLocation } from "react-router-dom"
import { get, ucFirst, update } from "../_utils/helper"
import { useState, useContext, useEffect } from 'react'
import { MyContext } from '../App'
import { Row, Col, message, Form, Input, Button } from "antd"
import axios from "axios"
import { Image } from "../components/Image"
import { BsCheck2Circle } from "react-icons/bs"
import dayjs from "dayjs"
const domain = window.location.hostname
const brand = domain.includes("boxguard") ? "boxguard" : "fastquote"

export function Login() {

      const { AppData, setUserData } = useContext(MyContext)
      const navigate = useNavigate()
      const location = useLocation()
      const queryParams = new URLSearchParams(location.search)

      const [ loading, setLoading ] = useState(false)
      const [ complete, setComplete ] = useState(false)
      const [ btnText, setBtnText ] = useState('View My Account')

      const getPolicy = async (id) => {

            let params = {
                  brand,
                  table: 'policies',
                  query: {
                        where: {
                              id,
                              [ `docs_issued IS NOT NULL` ]: "",
                        }
                  }
            }

            const data = await get(params, AppData)

            // Check magiclink hasn't expired
            if (data.status === "success") {

                  if (data.result.length > 0) {

                        if (dayjs(data.result[ 0 ].magiclink).isAfter(dayjs().subtract(15, 'm'))) {

                              const d = {
                                    brand,
                                    table: "policies",
                                    data: {
                                          where: { id },
                                          data: { client_login: data.result[ 0 ].client_login + 1 }
                                    }
                              }

                              console.log(d)

                              await update(d, AppData)

                              setUserData({ policy: data.result[ 0 ], authenticated: true })
                              message.success(`Successful login: ${ucFirst(data.result[ 0 ].first_name)} ${ucFirst(data.result[ 0 ].last_name)}`)
                              navigate("/")

                        } else {

                              message.error("Your link has expired. Enter your policy number and try again")
                              navigate("/")

                        }

                  } else {

                        message.error("We cannot locate a policy with this ID")

                  }

            } else {

                  message.error("Whoops. Something went wrong")

            }

      }

      useEffect(() => {

            if (queryParams.has('m')) {

                  (async () => { await getPolicy(queryParams.get('m')) })()

            }

            // eslint-disable-next-line
      }, [])

      async function onFinish(values) {

            setLoading(true)
            setBtnText('Please wait')

            let result = await axios({
                  method: 'post',
                  url: `${AppData.api_base}/magiclink/create`,
                  data: { policy: values.policy }
            })

            setLoading(false)

            if (result.data.status === "success") {
                  setComplete(true)
            }

            else {
                  message.error(result.data.result)
                  setBtnText('View My Account')
            }

      }

      return (
            <>
                  <Row justify="center">

                        <Col xs={22} md={8} style={{ textAlign: 'center', marginTop: "150px" }}>

                              <Image
                                    src={AppData.style.logo}
                                    width={175}
                                    alt={`${ucFirst(AppData.brand)} Logo`}
                                    clickable={false}
                                    navigate={{}}
                              />

                              <Row justify="center" style={{ marginTop: '25px', padding: '75px 0 50px 0', background: 'rgba(0,0,0,0.01)' }}>

                                    <Col xs={22} lg={16}>

                                          {!loading && !queryParams.has('m')
                                                ? complete
                                                      ? <>
                                                            <BsCheck2Circle style={{ fontSize: "200%", marginBottom: "15px", color: "green" }} />
                                                            <h2>Check your inbox</h2>
                                                            <p>We've sent you a temporary link that will log you straight in without needing a password.</p>
                                                      </>
                                                      :
                                                      <>
                                                            <h2 style={{ marginBottom: "25px" }}>Enter your policy number</h2>

                                                            <Form
                                                                  name="login"
                                                                  layout="vertical"
                                                                  initialValues={{ remember: true }}
                                                                  onFinish={onFinish}
                                                            >
                                                                  <Form.Item
                                                                        name="policy"
                                                                        rules={[
                                                                              { required: true, message: 'Please enter your policy number' }
                                                                        ]}
                                                                  >
                                                                        <Input style={{ textAlign: "center", fontSize: "120%" }} />
                                                                  </Form.Item>

                                                                  <Form.Item>

                                                                        <Button type="primary" style={{ backgroundColor: AppData.style.color }} htmlType="submit" loading={loading}>
                                                                              {btnText}
                                                                        </Button>

                                                                  </Form.Item>

                                                            </Form>

                                                      </>
                                                : "Please wait ..."}

                                    </Col>

                              </Row>

                        </Col >

                  </Row >

            </>
      )

}
