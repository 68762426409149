import axios from "axios"

export async function get(d, AppData) {
      return axios.post(`${AppData.api_base}/read/${d.brand}/${d.table}`, d.query).then(data => { return data.data })
}

export async function save(d, AppData) {
      return axios.post(`${AppData.api_base}/create/${d.brand}/${d.table}`, d.data).then(data => { return data.data })
}

export async function update(d, AppData) {
      return axios.post(`${AppData.api_base}/update/${d.brand}/${d.table}`, d.data).then(data => { return data.data })
}

export function round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export function isMobile() {
      return window.innerWidth < 500 ? true : false
}

export function ucFirst(str) {
      str = str.toLowerCase()
      str = str.charAt(0).toUpperCase() + str.slice(1)
      return str
}

export function deepMerge(target, ...sources) {
      if (!sources.length) return target
      const source = sources.shift()

      if (isObject(target) && isObject(source)) {
            for (const key in source) {
                  if (isObject(source[ key ])) {
                        if (!target[ key ]) Object.assign(target, { [ key ]: {} })
                        deepMerge(target[ key ], source[ key ])
                  } else {
                        Object.assign(target, { [ key ]: source[ key ] })
                  }
            }
      }

      return deepMerge(target, ...sources)
}

function isObject(item) {
      return (item && typeof item === 'object' && !Array.isArray(item))
}