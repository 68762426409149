import { ucFirst, round } from "../../../helper"
import dayjs from "dayjs"

export const helpers = {
      getBrand,
      getDriverType,
      hasPl,
      hasEl,
      getGitInsurer,
      getLiabInsurer,
      getVersion,
      getSummary,
      getGitBody,
      getPlBody,
      getElBody,
      getSof1,
      getSof2,
      getRiskHandling,
      getEndorsements
}

// These are local helper functions specifically used for this template ONLY
function getBrand(p) {
      return p.policy_number.toLowerCase().includes("fq")
            ? "fastquote"
            : "boxguard"
}

function getDriverType(p) {
      return p.driver_type.toLowerCase() === "c"
            ? "courier"
            : "remover"
}

function getGitInsurer(p) {
      return getBrand(p) === "fastquote"
            ? "Royal & Sun Alliance Insurance Ltd"
            : "Accelerant Insurance Europe SA"
}

function hasPl(p) {
      return p.pl === "0" ? false : true
}

function hasEl(p) {
      return p.el === "0" ? false : true
}

function isHighRisk(p) {
      return p?.qs_excluded === 'y' || p?.highrisk === '1' ? true : false
}

function getLiabInsurer(p) {
      return hasPl(p)
            ? getBrand(p) === "fastquote" ?
                  getDriverType(p) === "courier"
                        ? "Chaucer Insurance Company DAC"
                        : "Accelerant Insurance Europe SA"
                  : "Accelerant Insurance Europe SA"
            : "-"
}

function getVersion(p) {
      return getBrand(p) === "fastquote"
            ? getDriverType(p) === "courier"
                  ? "Courier Combined v9.0 Oct 2021"
                  : "Remover Combined v23.03.20"
            : getDriverType(p) === "courier"
                  ? "v23.03.20B"
                  : "v23.03.20B"
}

function getSummary(p) {

      const address = `${p.address_1}, ${p.address_2.length > 0 ? `${p.address_2}, ` : ``}${p.address_3.length > 0 ? `${p.address_3}, ` : ``}${p.town}, ${p.postcode}`
      const period = `${dayjs(p.start_date).format("DD-MM-YYYY")} til ${dayjs(p.start_date).add(365, 'd').format("DD-MM-YYYY")}`
      const premium = `£${parseFloat(p.gross_premium).toFixed(2)} (inc ${round(parseFloat(p.ipt_perc) * 100, 2).toFixed(0)}% IPT and £${parseFloat(p.fee).toFixed(2)} fee)`

      const body = [
            [ 'The Insured', p.legal_entity_name ],
            [ 'Address', address ],
            [ 'Business Description', ucFirst(getDriverType(p)) ],
            [ 'Period of Insurance', period ],
            [ 'Premium', premium ],
            [ 'Broker', `Coversure Midlands Limited t/as ${ucFirst(getBrand(p))}` ],
            [ 'Goods in Transit Insurer', getGitInsurer(p) ],
            [ 'Liability Insurer', getLiabInsurer(p) ],
            [ 'Policy Wording', getVersion(p) ],
            [ 'Territorial Limits', p.europe !== "0" ? `British Isles & Europe` : `British Isles only` ]
      ]

      return body

}

function getGitBody(p) {

      const contract_conditions = p?.bar && p.bar > 0 ? 'BAR Conditions' : 'Full Value Liability'

      const body = [
            [ { text: 'Goods in Transit Insurance', bold: true, style: 'green' }, { text: 'Insured', bold: true, style: 'green' } ],
            [ 'Contract Conditions', contract_conditions ],
            [ 'Vehicle Limit', `£${p.git},000 x ${p.vehicles} vehicle${p.vehicles > 1 ? 's' : ''}` ],
            [ 'Excess Applicable', `£${parseFloat(p.xsGit).toFixed(2)}  (each and every claim)` ],
            [ 'Own goods limit including hand held scanners', 'Maximum £5,000 (£50 excess for hand held scanners)' ]
      ]

      return body

}

function getPlBody(p) {

      const body = [
            [
                  {
                        text: 'Public Liability Insurance',
                        bold: true,
                        style: hasPl(p) ? 'green' : 'red'
                  },
                  {
                        text: hasPl(p) ? 'Insured' : 'Not Insured',
                        bold: true,
                        style: hasPl(p) ? 'green' : 'red'
                  }
            ]
      ]

      if (hasPl(p)) {
            body.push([ 'Limit of Liability', `£${p.pl},000,000 (Any one occurrence or all occurrences of a series consequent upon or attributable to one source or original cause)` ])
            body.push([ 'Excess Applicable', `£${parseFloat(p.xsPl).toFixed(2)} (each and every claim arising in respect of damage to third party property` ])
      }

      return body

}

function getElBody(p) {

      const body = [
            [
                  {
                        text: 'Employer\'s Liability Insurance',
                        bold: true,
                        style: hasEl(p) ? 'green' : 'red'
                  },
                  {
                        text: hasEl(p) ? hasTempEl(p) ? 'Insured – See Temporary Employees Extension' : 'Insured' : 'Not Insured',
                        bold: true,
                        style: hasEl(p) ? 'green' : 'red'
                  }
            ]
      ]

      if (p?.employees && p.employees > 0) {
            body.push([ 'Number of Employees', p.employees ])
      }

      if (hasEl(p)) {
            body.push([ 'Limit of Liability', `£10,000,000 (Any one occurrence or all occurrences of a series consequent upon or attributable to one source or original cause)` ])
      }


      return body

}

function hasTempEl(p) {
      return p.el.toLowerCase().includes("t") ? true : false
}

function isYesNo(e) {
      return e === "1" || e === "y" ? "Yes" : "No"
}

function getSof1(p) {

      const convicted = p?.qs_criminal === 'y' || p?.convict === '1'

      const convicted_responses = convicted
            ?
            [
                  [ `Charged or convicted in the UK with a criminal offence other than a motoring conviction?`, 'Yes' ],
                  [ `Is the conviction spent under the UK Rehabilitation of Offenders Act 1974?`, 'Yes' ]
            ]
            :
            [
                  [ `Charged or convicted in the UK with a criminal offence other than a motoring conviction?`, 'No' ]
            ]

      const body = [
            ...convicted_responses,
            [ `Declined or refused insurance cover or had cover cancelled or been subject to special terms in respect of any cover that you have asked us to insure under this policy?`, 'No' ],
            [ `Declared bankrupt, subject to bankruptcy proceedings or to any voluntary or mandatory insolvency or winding up proceedings either as individuals or in connection with any other business?`, 'No' ],
            [ `Disqualified under the Company Directors Disqualification Act 1986 or any subsequent legislation?`, 'No' ]
      ]

      return body

}

function getSof2(p) {

      const highrisk_item = isHighRisk(p)
            ? p?.qs_excitem === 'otherveh' || p?.highrisk_v === '1'
                  ? 'Other vehicles or motorbikes'
                  : 'Refrigerated or frozen goods'
            : ''

      const highrisk_responses = isHighRisk(p)
            ?
            [
                  [ `Do you ever knowingly carry motor vehicles, motorcycles, frozen or chilled foods, frozen or chilled pharmaceuticals, or livestock?`, 'Yes' ],
                  [ `Selected risk item: ${highrisk_item}`, 'Yes' ]
            ]
            :
            [
                  [ `Do you ever knowingly carry motor vehicles, motorcycles, frozen or chilled foods, frozen or chilled pharmaceuticals, or livestock?`, 'No' ],
            ]

      const body = [
            [ `Do you pass any more than 10 % of your work to bona fide subcontractors(i.e. someone who uses their own van and has their own goods in transit insurance)?`, 'No' ],
            [ `Have you had a claim on any of the following policies in the last three years: Goods in Transit, Public Liability and / or Employers Liability?`, 'No' ],
            [ `Hazardous goods must be carried in accordance with the limited quantity exemptions and the 1,000 point rule in 1.1.3.6 of ADR 2019. If you do carry hazardous goods, are they ever outside of these exemption thresholds?`, 'No' ],
            ...highrisk_responses,
            [ `Do you install or remove dishwashers or washing machines?`, p?.appliances ? isYesNo(p.appliances) : isYesNo(p.qs_whitegoods) ]
      ]

      return body

}

function getRiskHandling(p, style) {

      const brand = getBrand(p)

      let body

      if (p?.familiar && p.familiar === "1") {

            body = [
                  { text: `Risk Handling:`, style: 'p' },
                  {
                        style: 'table',
                        table: {
                              widths: [ '95%', '*' ],
                              body: [
                                    [ `Are you familiar with HSE guidance on personal protective equipment, manual handling and injury through pushing and pulling.`, isYesNo(p.familiar) ],
                                    [ `Do you take steps to prevent, in so far as is possible, lifting and handling and pushing and pulling operations by, for example, providing trucks, skids and dollies?`, isYesNo(p.prevent) ],
                                    [ `Are all employees issued with appropriate Personal Protective Equipment (PPE)?`, isYesNo(p.ppe) ],
                                    [ `Have you undertaken appropriate risk assessments? `, isYesNo(p.riskassess) ],
                                    [ `Have all employees received appropriate training in the use of PPE; in lifting and handling and in pushing and pulling operations. In the event of a claim, by one of your employees, could you provide evidence?`, isYesNo(p.training) ]
                              ]
                        },
                        layout: style.styles.table,
                  },
                  { text: `Guidance on can be downloaded from the HSE website. See link below:`, style: 'p' },
                  { text: `https://hse.gov.uk/guidance`, link: 'https://www.hse.gov.uk/guidance/index.htm', style: 'p' },

            ]

      }

      return body

}

function getEndorsements(p) {

      let body = []

      // Handle Temp EL Endorsement
      if (hasTempEl(p)) {
            body.push({
                  text: `Temporary Employees Extension: If You engage Persons Employed on a temporary basis We will provide indemnity to You against legal liability for damages in respect of Injury to such temporary workers provided that the combined total number of days during which such temporary workers are engaged does not exceed 30 days in any one period of Insurance in respect of all temporary workers employed.`,
                  style: 'p'
            })
      }

      // Handle Appliance Risk Endorsement
      const appliances = p?.appliances ? p.appliances : p.qs_whitegoods

      if (isYesNo(appliances) === "Yes") {
            body.push({
                  text: `New clause(s) to apply: The indemnity provided by the Public Liability section excludes damage to Property caused by escape of water. The indemnity provided by the Public Liability section is subject to an increased excess of £500.`,
                  style: 'p'
            })
      }

      // Handle high risk items
      if (isHighRisk(p)) {
            if (p?.qs_excitem === 'otherveh' || p?.highrisk_v === '1') {
                  body.push({
                        text: `New clause(s) to apply: - Motorbikes, quad bikes inside the vehicle only. ${getDriverType(p) === "courier" ? `No more than 50% of your normal work. ` : ``}Higher excess of £500. Cover for theft, fire and impact excludes unattended vehicles`,
                        style: 'p'
                  })
            } else {
                  body.push({
                        text: `New clause(s) to apply: Cover for theft, fire and impact - No cover for deterioration of stock for failure of equipment through malfunction, breakdown or lack of maintenance or not setup correctly.`,
                        style: 'p'

                  })
            }
      }

      // Put header on if any data is being returned
      if (body.length > 0) body.unshift({ text: 'Endorsements', style: 'h3', margin: [ 0, 40, 0, 0 ] })

      return body

}