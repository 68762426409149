import { useEffect, useReducer, createContext } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { AwsRum } from 'aws-rum-web'
import { config as c, brand } from "./_config/config"
import dayjs from "dayjs"
import { Home } from "./routes/Home"
import { Login } from "./routes/Login"
import { Admin } from "./routes/Admin"
import { Backdoor } from "./routes/Backdoor.js"

const b = window.location.hostname.includes("fastquote")
      ? 'fastquote'
      : window.location.hostname.includes("boxguard")
            ? 'boxguard'
            : 'dasher'

const e = window.location.hostname.includes("stage") || window.location.hostname.includes("local") ? 'stage' : 'prod'

if (e === "prod") {

      let config
      let APPLICATION_ID = ''
      let APPLICATION_VERSION = ''
      let APPLICATION_REGION = ''

      if (b === "boxguard") {
            config = {
                  sessionSampleRate: 1,
                  guestRoleArn: "arn:aws:iam::731016139481:role/RUM-Monitor-eu-west-2-731016139481-1746919141071-Unauth",
                  identityPoolId: "eu-west-2:954aecf9-80c6-4311-bdc9-b8c1267a93fd",
                  endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
                  telemetries: [ "performance", "errors", "http" ],
                  allowCookies: true,
                  enableXRay: false
            }

            APPLICATION_ID = '5faffa30-d6bc-4520-a5cf-9a4198f3011d'
            APPLICATION_VERSION = '1.0.0'
            APPLICATION_REGION = 'eu-west-2'
      }

      else {
            config = {
                  sessionSampleRate: 1,
                  guestRoleArn: "arn:aws:iam::731016139481:role/RUM-Monitor-eu-west-2-731016139481-3246751241071-Unauth",
                  identityPoolId: "eu-west-2:f005ca11-9d20-478c-8cdd-22fe4ab82ff1",
                  endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
                  telemetries: [ "performance", "errors", "http" ],
                  allowCookies: true,
                  enableXRay: false
            }

            APPLICATION_ID = 'bea0b868-2852-4038-ad65-9967ef55d3b7'
            APPLICATION_VERSION = '1.0.0'
            APPLICATION_REGION = 'eu-west-2'
      }

      try {
            const awsRum = new AwsRum(
                  APPLICATION_ID,
                  APPLICATION_VERSION,
                  APPLICATION_REGION,
                  config
            )
      } catch (error) {
            // Ignore errors thrown during CloudWatch RUM web client initialization
      }

}

export const MyContext = createContext()

export default function App() {

      const [ AppData, setAppData ] = useReducer((oldData, newItem) => { return ({ ...oldData, ...newItem }) }, { version: "1.23.33", brand: b, ...c[ e ], ...brand[ b ] })
      const [ UserData, setUserData ] = useReducer((oldData, newItem) => { return ({ ...oldData, ...newItem }) }, { authenticated: false })

      // Logs AppData changes
      useEffect(() => {
            console.log(`AppData ${dayjs().format("HH:mm:ss.SS")}`, JSON.stringify(AppData))
      }, [ AppData ])

      // Logs UserData changes
      useEffect(() => {
            console.log(`UserData ${dayjs().format("HH:mm:ss.SS")}`, JSON.stringify(UserData))
      }, [ UserData ])

      if (UserData.authenticated) {

            return (
                  <MyContext.Provider value={{ AppData, setAppData, UserData, setUserData }}>
                        <Router>
                              <Routes>
                                    <Route path="/" element={<Home />} />
                              </Routes>
                        </Router>
                  </MyContext.Provider>
            )

      }

      else {

            return (
                  <MyContext.Provider value={{ AppData, setAppData, UserData, setUserData }}>
                        <Router>
                              <Routes>
                                    <Route path="/admin" element={<Admin />} />
                                    <Route path="/backdoor" element={<Backdoor />} />
                                    <Route path="*" element={<Login />} />
                              </Routes>
                        </Router>
                  </MyContext.Provider>
            )

      }


}
