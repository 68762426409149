import { useLocation, useNavigate } from "react-router-dom"
import { get } from "../_utils/helper"
import { useEffect, useContext } from 'react'
import { MyContext } from '../App'
import { Row, Col, message } from "antd"
const domain = window.location.hostname
const brand = domain.includes("boxguard") ? "boxguard" : "fastquote"

export function Backdoor() {

      const navigate = useNavigate()
      const location = useLocation()
      const queryParams = new URLSearchParams(location.search)
      const { AppData, setUserData } = useContext(MyContext)

      async function validateRequest(u, p) {

            // Validate user
            let params = {
                  brand: "dasher",
                  table: 'users',
                  query: {
                        where: {
                              id: u,
                              status: 1,
                              [ "myPermission > 0" ]: ""

                        }
                  }
            }

            const user = await get(params, AppData)

            // console.log('user', user)

            if (user.status === "success" && user.result.length > 0) {

                  // Get policy
                  let params = {
                        brand,
                        table: 'policies',
                        query: {
                              where: {
                                    id: p,
                                    [ "docs_issued IS NOT NULL" ]: ""
                              }
                        }
                  }

                  const policy = await get(params, AppData)

                  // console.log('policy', policy)

                  if (policy.status === "success" && policy.result.length > 0) {

                        setUserData({ policy: policy.result[ 0 ], authenticated: true })
                        message.success(`Hi ${user.result[ 0 ].first_name}. You just entered ${policy.result[ 0 ].first_name}'s backdoor.`, 3)
                        navigate("/")

                  } else {

                        message.error("Sorry. Please check that policy number and try again.")
                        return

                  }

            }

      }

      useEffect(() => {

            if (queryParams.has('user') && queryParams.has('id')) {

                  (async () => {

                        await validateRequest(queryParams.get('user'), queryParams.get('id'))

                  })()

            }

      }, [])









      return (<Row align="middle"><Col span={24} style={{ marginTop: 150, textAlign: 'center' }}>authenticating request ..</Col></Row>)

}
