import { useNavigate } from "react-router-dom"
import { get, ucFirst } from "../_utils/helper"
import { useState, useContext } from 'react'
import { MyContext } from '../App'
import { Image } from "../components/Image"
import { Row, Col, message, Form, Input, Button } from "antd"
const domain = window.location.hostname
const brand = domain.includes("boxguard") ? "boxguard" : "fastquote"

export function Admin() {

      const { AppData, setUserData } = useContext(MyContext)
      const navigate = useNavigate()

      const [ loading, setLoading ] = useState(false)
      const [ btnText, setBtnText ] = useState('Access Account')

      async function onFinish(values) {

            console.log(values)
            console.log(values.policy.toUpperCase())

            setLoading(true)
            setBtnText("Please wait ...")

            if (!values.policy.toUpperCase().includes("FQ-") && !values.policy.toUpperCase().includes("BG-")) {
                  message.error("Sorry. Please check that policy number and try again.")
                  setBtnText("Access Account")
                  setLoading(false)
                  return
            }

            // Validate user
            let params = {
                  brand: "dasher",
                  table: 'users',
                  query: {
                        where: {
                              username: values.username.toLowerCase(),
                              password: values.password,
                              status: 1,
                              [ "myPermission > 0" ]: ""

                        }
                  }
            }

            const user = await get(params, AppData)

            console.log('user', user)

            if (user.status === "success" && user.result.length > 0) {

                  // Get policy
                  let params = {
                        brand: values.policy.includes("FQ") ? "fastquote" : "boxguard",
                        table: 'policies',
                        query: {
                              where: {
                                    policy_number: values.policy,
                                    [ "docs_issued IS NOT NULL" ]: ""
                              }
                        }
                  }

                  const policy = await get(params, AppData)

                  console.log('policy', policy)

                  if (policy.status === "success" && policy.result.length > 0) {

                        setUserData({ policy: policy.result[ 0 ], authenticated: true })
                        message.success(`Hi ${user.result[ 0 ].first_name}. You are logged in as ${values.policy}.`, 3)
                        setLoading(false)
                        navigate("/")

                  } else {

                        message.error("Sorry. Please check that policy number and try again.")
                        setLoading(false)
                        setBtnText("Access Account")
                        return

                  }

            }

            else {
                  message.error("Sorry. Please check your login details and try again.")
                  setBtnText("Access Account")
                  setLoading(false)
                  return
            }

      }

      return (
            <>
                  <Row justify="center">

                        <Col xs={22} md={8} style={{ textAlign: 'center', marginTop: "150px" }}>

                              <Image
                                    src={AppData.style.logo}
                                    width={175}
                                    alt={`${ucFirst(AppData.brand)} Logo`}
                                    clickable={false}
                                    navigate={{}}
                              />

                              <Row justify="center" style={{ marginTop: '25px', padding: '75px 0 50px 0', background: 'rgba(0,0,0,0.01)' }}>

                                    <Col xs={22} lg={16}>

                                          {!loading
                                                ?
                                                <>
                                                      <h2 style={{ marginBottom: "25px" }}>Administrative Access</h2>

                                                      <Form
                                                            name="login"
                                                            layout="vertical"
                                                            initialValues={{ remember: true }}
                                                            onFinish={onFinish}
                                                      >
                                                            <Form.Item
                                                                  name="policy"
                                                                  rules={[
                                                                        { required: true, message: 'Please enter the policy number' }
                                                                  ]}
                                                            >
                                                                  <Input style={{ textAlign: "center", fontSize: "120%" }} placeholder="POLICY NUMBER" />
                                                            </Form.Item>
                                                            <Form.Item
                                                                  name="username"
                                                                  rules={[
                                                                        { required: true, message: 'Please enter your username/email' }
                                                                  ]}
                                                            >
                                                                  <Input style={{ textAlign: "center", fontSize: "120%" }} placeholder="ADMINISTRATOR EMAIL" />
                                                            </Form.Item>
                                                            <Form.Item
                                                                  name="password"
                                                                  rules={[
                                                                        { required: true, message: 'Please enter your password' }
                                                                  ]}
                                                            >
                                                                  <Input style={{ textAlign: "center", fontSize: "120%" }} placeholder="ADMINISTRATOR PASSWORD" />
                                                            </Form.Item>

                                                            <Form.Item>

                                                                  <Button type="primary" style={{ backgroundColor: AppData.style.color }} htmlType="submit" loading={loading}>
                                                                        {btnText}
                                                                  </Button>

                                                            </Form.Item>

                                                      </Form>

                                                </>
                                                : "Please wait ..."}

                                    </Col>

                              </Row>

                        </Col >

                  </Row >

            </>
      )

}
